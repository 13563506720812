<template>
  <div v-loading="showLoadingAnimation" class="documents-list-table">
    <el-table
      :data="tableData"
      class="table"
      size="medium"
      ref="table"
      highlight-current-row
      @current-change="handleCurrentChange"
      @keydown.esc.native="cancelEditRowData()"
      @keydown.enter.exact.native="saveRowData()"
      @cell-click="scrollToSelectedRow"
      :row-key="tableData.id"
      row-class-name="table__row"
      empty-text="There are no documents in this category"
    >
      <el-table-column label="Series" min-width="138">
        <template slot-scope="scope">
          <el-input
            v-if="editingRow.rowIndex === scope.$index"
            v-model.trim="editingRow.data.series"
            autofocus
            clearable
          />
          <span v-else v-html="scope.row.series" />
        </template>
      </el-table-column>
      <el-table-column prop="link" label="Link" min-width="180">
        <template slot-scope="scope">
          <el-input
            v-if="editingRow.rowIndex === scope.$index"
            v-model="editingRow.data.searchResults.link"
            clearable
          />
          <a v-else v-html="scope.row.searchResults.link" />
        </template>
      </el-table-column>
      <el-table-column label="Fechas" min-width="105">
        <template slot-scope="scope">
          <el-date-picker
            v-if="editingRow.rowIndex === scope.$index"
            v-model="editingRow.data.searchDate"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            :clearable="false"
            type="date"
            :picker-options="datePrickerOptions"
          />
          <span v-else v-html="scope.row.searchDate" />
        </template>
      </el-table-column>
      <el-table-column min-width="300" type="expand">
        <template slot-scope="scope">
          <div class="table__expand-column">
            <div class="table__expand-row">
              <h4 class="table__item-title">Source:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.source"
                type="text"
                clearable
                :autosize="{ minRows: 2, maxRows: 7 }"
              />
              <p v-else v-html="scope.row.source" />
            </div>
            <!-- Some data of referenced_tweets is null in twitter,
              so we need to check if it is null before accessing the data. -->
            <div v-if="editingRow.data.source === 'twitter' &&
            editingRow.data.graph_data.referenced_tweets !== null">
              <div class="table__expand-row">
                <h4 class="table__item-title">Tweet id:</h4>
                <el-input
                  v-if="editingRow.rowIndex === scope.$index"
                  v-model="editingRow.data.graph_data.referenced_tweets[0].id"
                  type="text"
                  clearable
                  :autosize="{ minRows: 2, maxRows: 7 }"
                />
                <p v-else v-html="scope.row.graph_data.referenced_tweets[0].id" />
              </div>
              <div class="table__expand-row">
                <h4 class="table__item-title">Tipo Tweet:</h4>
                <el-input
                  v-if="editingRow.rowIndex === scope.$index"
                  v-model="editingRow.data.graph_data.referenced_tweets[0].type"
                  type="text"
                  clearable
                  :autosize="{ minRows: 2, maxRows: 7 }"
                />
                <p v-else v-html="scope.row.graph_data.referenced_tweets[0].type" />
              </div>
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Author:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchResults.author"
                type="text"
                clearable
                :autosize="{ minRows: 2, maxRows: 7 }"
              />
              <p v-else v-html="scope.row.searchResults.author" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Location author:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchResults.authorLocation"
                clearable
              />
              <p v-else v-html="scope.row.searchResults.authorLocation" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Date of publication:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchResults.dateOfPublication"
                clearable
              />
              <p v-else v-html="scope.row.searchResults.dateOfPublication" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Search Date:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchDate"
                clearable
              />
              <p v-else v-html="scope.row.searchDate" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Location receiver:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchResults.receiverLocation"
                clearable
                type="text"
                :autosize="{ minRows: 2, maxRows: 7 }"
              />
              <span v-else v-html="scope.row.searchResults.receiverLocation" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Country:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchCountry"
                clearable
              />
              <p v-else v-html="scope.row.searchCountry" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Criterion:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchCriterion"
                clearable
              />
              <p v-else v-html="scope.row.searchCriterion" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Query:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchQuery"
                clearable
              />
              <p v-else v-html="scope.row.searchQuery" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Text:</h4>
              <el-input
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.searchResults.text"
                type="textarea"
                clearable
                :autosize="{ minRows: 2, maxRows: 7 }"
              />
              <p v-else v-html="scope.row.searchResults.text" />
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Discard:</h4>
              <el-switch
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.discard"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
              <el-switch
                disabled
                v-else
                v-model="scope.row.discard"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Reviewed:</h4>
              <el-switch
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.reviewed"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
              <el-switch
                disabled
                v-else
                v-model="scope.row.reviewed"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Historical Memory:</h4>
              <el-switch
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.historicalMemory"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
              <el-switch
                disabled
                v-else
                v-model="scope.row.historicalMemory"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Political Education:</h4>
              <el-switch
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.politicalEducation"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
              <el-switch
                disabled
                v-else
                v-model="scope.row.politicalEducation"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
            </div>
            <div class="table__expand-row">
              <h4 class="table__item-title">Image Of Past:</h4>
              <el-switch
                v-if="editingRow.rowIndex === scope.$index"
                v-model="editingRow.data.imageOfPast"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
              <el-switch
                disabled
                v-else
                v-model="scope.row.imageOfPast"
                active-color="#42ad0d"
                inactive-color="#c0c4cc"
                active-text="true"
                inactive-text="false"
              >
              </el-switch>
            </div>
            <div
              class="table__button-group table__button-group--inside-expand-row"
              v-if="editingRow.rowIndex === scope.$index"
            >
              <el-button
                type="danger"
                icon="ion ion-close-outline"
                plain
                size="mini"
                class="table__button"
                @click="cancelEditRowData()"
              >
                Cancel
              </el-button>
              <el-button
                type="success"
                plain
                icon="el-icon-check"
                size="mini"
                class="table__button"
                @click="saveRowData()"
                @keydown.tab.native="editRowData(editingRow.rowIndex + 1)"
              >
                Save
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Acciones" width="100">
        <template slot-scope="scope">
          <div
            class="table__button-group"
            v-if="editingRow.rowIndex !== scope.$index"
            id="prueba"
          >
            <el-tooltip content="Edit" placement="top" :open-delay="400">
              <el-button
                circle
                size="small"
                icon="el-icon-edit"
                @click="editRowData(scope)"
                class="table__button"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip content="Delete" placement="top" :open-delay="400">
              <el-button
                circle
                size="small"
                icon="el-icon-delete"
                @click="deleteRows(scope)"
                class="table__button"
              >
              </el-button>
            </el-tooltip>
          </div>
          <div
            class="table__button-group"
            v-if="editingRow.rowIndex === scope.$index"
          >
            <el-tooltip content="Cancel" placement="top" :open-delay="400">
              <el-button
                type="danger"
                icon="ion ion-close-outline"
                plain
                circle
                size="mini"
                class="table__button"
                @click="cancelEditRowData()"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip content="Save" placement="top" :open-delay="400">
              <el-button
                type="success"
                plain
                icon="el-icon-check"
                circle
                size="mini"
                class="table__button"
                @click="saveRowData()"
                @keydown.tab.native="editRowData(editingRow.rowIndex + 1)"
              >
              </el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        :background="true"
        :page-size="pageSize"
        :total="totalLength"
        :current-page="currentPage"
        @current-change="setPage"
        layout="prev, pager, next"
      >
      </el-pagination>
      <span slot="total" class="el-pagination__total">
        Total documentos {{ totalLength }} </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { Message } from "element-ui";

export default {
  name: "DocumentsListTable",
  props: {
    totalLength: { type: Number, required: true, default: 0 },
    flagDate: { type: Boolean, required: true, default: false },
    flagFilter: { type: Boolean, required: true, default: false },
    currentPage: { type: Number, required: true, default: 0 },
    page: Number,
  },
  components: {
    /* StatusIcon, */
  },
  data() {
    return {
      tableData: [],
      pageSize: 20,
      filtering: this.flagFilter,
      editingRow: {
        rowIndex: null,
        data: {},
      },
      cancelEdit: false,
      datePrickerOptions: {
        disabledDate: (time) => time.getTime() > Date.now(),
        firstDayOfWeek: 1,
      },
      changeStatusButtonLoadingAnimation: false,
      showLoadingAnimation: true,
    };
  },
  computed: {
    ...mapState("documents", ["documents", "total"]),
    ...mapGetters("documents", ["showParams", "showFilterParams"]),
    ...mapState("user", ["user"]),
  },
  watch: {
    documents: {
      handler() {
        this.fillTableData();
      },
      deep: true,
    },
  },
  async mounted() {
    this.showLoadingAnimation = true;
    await this.fillTableData();
    this.showLoadingAnimation = false;
  },
  created() {
    if (this.$route.params.page) {
      this.page = parseInt(this.$route.params.page, 10);
      this.setPage(this.page);
    }
  },
  methods: {
    ...mapActions("documents", [
      "patchDocument",
      "deleteDocument",
      "getAllDocuments",
      "getFilterDocuments",
      "getParams",
      "getFilterParams",
    ]),
    async fillTableData() {
      if (this.documents) {
        this.tableData = this.documents;
        this.totalLength = this.total;
      }
    },
    editRowData(scope) {
      this.collapseAllRows();
      this.$refs.table.toggleRowExpansion(scope.row, true);
      this.editingRow.rowIndex = scope.$index;
      this.editingRow.data = { ...this.tableData[scope.$index] };
    },
    cancelEditRowData() {
      this.editingRow = {
        rowIndex: null,
        data: {},
      };
      this.cancelEdit = true;
    },
    async saveRowData() {
      this.showLoadingAnimation = true;
      const payload = {
        series: this.editingRow.data.series,
        searchCountry: this.editingRow.data.searchCountry,
        searchDate: this.editingRow.data.searchDate,
        searchCriterion: this.editingRow.data.searchCriterion,
        searchQuery: this.editingRow.data.searchQuery,
        apiVersion: "",
        searchResults: {
          text: this.editingRow.data.searchResults.text,
          link: this.editingRow.data.searchResults.link,
          dateOfPublication:
            this.editingRow.data.searchResults.dateOfPublication,
          emojis: "",
          rating: 0,
          imagesLinks: [""],
          linksInTweet: [""],
          author: this.editingRow.data.searchResults.author,
          authorId: "",
          authorLocation: this.editingRow.data.searchResults.authorLocation,
          receiver: "",
          receiverId: "",
          receiverLocation: this.editingRow.data.searchResults.receiverLocation,
          sourceDevice: "",
          retweetsNumber: 0,
          likesNumber: 0,
          entities: [""],
        },
        discard: this.editingRow.data.discard,
        reviewed: this.editingRow.data.reviewed,
        review: {
          reviewer: "",
          reviewDate: "",
          reviewComments: "",
        },
        source: this.editingRow.data.source,
        historicalMemory: this.editingRow.data.historicalMemory,
        imageOfPast: this.editingRow.data.imageOfPast,
        politicalEducation: this.editingRow.data.politicalEducation,
        username: this.user,
      };
      const response = await this.patchDocument({
        _id: this.editingRow.data._id,
        body: payload,
      });
      if (response.status === 200) {
        Message.success({
          message: `The document was edited successfully`,
          duration: 5000,
          showClose: true,
        });
      }
      this.cancelEditRowData();
      this.showLoadingAnimation = false;
    },
    async deleteRows(scope) {
      this.$confirm("¿Are you sure you want to delete this file?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async (willDelete) => {
          if (willDelete) {
            this.showLoadingAnimation = true;
            // eslint-disable-next-line prefer-destructuring
            const _id = scope.row._id;
            const response = await this.deleteDocument(_id);
            const body = {
              skip: (this.page - 1) * 20,
              limit: 20,
            };
            await this.getAllDocuments(body);
            this.showLoadingAnimation = false;
            if (response.status === 200) {
              this.$message({
                type: "success",
                message: "Delete completed",
              });
            }
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    handleCurrentChange(row) {
      this.cancelEditRowData();
      this.cancelEdit = false;
      this.collapseAllRows();
      const currentLink = row.searchResults.link;
      this.$store.commit("files/setCurrentLink", currentLink);
      this.$refs.table.toggleRowExpansion(row);
    },
    scrollToSelectedRow(row, column, cell) {
      const rowElement = cell.parentNode;
      const tableElement = document.querySelector(".el-table__body-wrapper");
      setTimeout(() => {
        tableElement.scrollTo({
          top: rowElement.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 0);
      const object = {
        $index: null,
        row,
      };
      this.tableData.forEach((item, index) => {
        if (item._id === row._id) {
          object.$index = index;
        }
      });
      if (!this.cancelEdit) {
        this.editRowData(object);
      }
      this.cancelEdit = false;
    },
    collapseAllRows() {
      this.tableData.forEach((item) => {
        this.$refs.table.toggleRowExpansion(item, false);
      });
    },
    async setPage(val) {
      this.$router.push({ name: this.$route.name, params: { page: val } }).catch((err) => {
        if (
          err.name !== 'NavigationDuplicated'
          && !err.message.includes(
            'Avoided redundant navigation to current location',
          )
        ) {
          // logError(err);
        }
      });
      const payload = {
        skip: (val - 1) * 20,
        limit: 20,
      };
      await this.getParams(payload);
      await this.getFilterDocuments();
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-list-table {
  width: 100%;
  height: 99%;
}
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.table {
  // ↓ must be set under 100% to avoid redimensional issues
  width: 99%;
  margin: auto;
  &__button-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
    &--inside-expand-row {
      justify-content: center;
      margin: 2rem 0;
      gap: 1rem;
    }
    &--vertical {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  &__button {
    margin: 0;
    &--action-change-status {
      width: 100%;
      border: none;
    }
  }
  &__expand-column {
    margin: 0 40px 0 40px;
  }
  &__expand-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    align-items: center;
  }
  &__item-title {
    margin: 14px;
  }
  ::v-deep .cell {
    word-break: normal;
  }
}
::v-deep {
  .table__row {
    cursor: pointer;
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .el-tag.el-tag--info {
    background-color: $accent-color-light;
    border-color: desaturate(darken($accent-color-light, 8%), 50%);
  }
  .el-select .el-tag__close.el-icon-close {
    background-color: desaturate(darken($accent-color-light, 8%), 50%);
  }
  .el-table thead {
    font-weight: 500;
    text-transform: uppercase;
    color: #43255c;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border-radius: 50%;
    margin: 0 5px;
    background-color: white;
    min-width: 30px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
  .el-pagination {
    margin-top: 15px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #303133;
    font-weight: 700;
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
