const documentStatusButtons = [
  {
    label: 'Twitter',
    value: 'twitter',
    iconLabel: 'ion ion-logo-twitter',
    route: 'twitter',
  },
  {
    label: 'Google',
    value: 'google',
    iconLabel: 'ion ion-logo-google',
    route: 'google',
  },
  {
    label: 'Youtube',
    value: 'youtube',
    iconLabel: 'ion ion-logo-youtube',
    route: 'youtube',
  },
  {
    label: 'Entrevistas',
    value: 'entrevistas',
    iconLabel: 'ion ion-people-outline',
    route: 'entrevista',
  },
  {
    label: 'Gráficas',
    value: 'graficas',
    iconLabel: 'ion ion-pie-chart',
    route: 'graficas',
  },
];

export default documentStatusButtons;
