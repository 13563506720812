<template>
  <div class="documents-list" v-loading="resizing" element-loading-text="Redimensionando">
    <div class="tools-bar">
      <div class="user-section">
        <div class="user-info">
          <p>Usuario Actual:</p>
          <h3>{{ user }}</h3>
        </div>
        <div class="user-assign">
          <div class="filter">
            <label for="filter__select" class="label filter__label"> Usuario: </label>
            <el-input
              placeholder="Nombre y Apellido"
              ref="user"
              @keyup.enter.native="sendUser"
              v-model="textUser"
              type="text"
              size="small"
              class="filter__input"
            >
              <span slot="suffix" v-show="textUser" @click="clearUserInput">
                <i
                  class="el-input__icon el-icon-circle-close el-input__clear"
                  style="cursor: pointer"
                ></i>
              </span>
            </el-input>
          </div>
          <el-button
            type="warning"
            plain
            size="small"
            @click="sendUser"
            :loading="filterButtonLoadingAnimation"
          >
            Enviar
          </el-button>
        </div>
      </div>
      <div style="padding-top: 7px; display: flex; flex-direction: column; gap: 0.5rem">
        <label class="label"> Fechas: </label>
        <div>
          <el-date-picker
            v-model="dates"
            start-placeholder="Fecha Inicial"
            end-placeholder="Fecha Final"
            type="daterange"
            lang="english"
            size="small"
            :picker-options="datePrickerOptions"
            class="filter__input"
            id="date-filter__date-picker"
            unlink-panels
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="tools-bar__item">
        <label class="label">
          Filtros
        </label>
        <el-popover placement="bottom" trigger="click" v-model="visible">
          <div class="close-container">
            <el-button size="mini" type="text" style="color: #c0c4cc" @click="closeContainerFilter"
              >Cancelar</el-button
            >
          </div>
          <el-input
            slot="reference"
            suffix-icon="el-icon-arrow-down"
            size="small"
            readonly
          >
          </el-input>
          <div class="filters-container">
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Autor: </label>
              <el-input
                placeholder="Buscar"
                ref="search"
                @keyup.enter.native="filterDocuments"
                v-model="author"
                type="text"
                size="small"
                class="filter__input"
              >
                <span
                  slot="suffix"
                  v-show="author"
                  @click="clearSearchInput('author')"
                >
                  <i
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    style="cursor: pointer"
                  ></i>
                </span>
              </el-input>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Ubicación del autor: </label>
              <el-input
                placeholder="Buscar"
                ref="search"
                @keyup.enter.native="filterDocuments"
                v-model="authorLocation"
                type="text"
                size="small"
                class="filter__input"
              >
                <span
                  slot="suffix"
                  v-show="authorLocation"
                  @click="clearSearchInput('authorLocation')"
                >
                  <i
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    style="cursor: pointer"
                  ></i>
                </span>
              </el-input>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Ubicación del receptor: </label>
              <el-input
                placeholder="Buscar"
                ref="search"
                @keyup.enter.native="filterDocuments"
                v-model="receiverLocation"
                type="text"
                size="small"
                class="filter__input"
              >
                <span
                  slot="suffix"
                  v-show="receiverLocation"
                  @click="clearSearchInput('receiverLocation')"
                >
                  <i
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    style="cursor: pointer"
                  ></i>
                </span>
              </el-input>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Serie: </label>
              <el-select
                placeholder="Selecciona una Serie"
                ref="search"
                v-model="series"
                filtereable
                size="small"
                class="filter__input"
              >
              <el-option v-for="item in seriesArr" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> País: </label>
              <el-select
                placeholder="Selecciona un País"
                ref="search"
                v-model="searchCountry"
                filtereable
                size="small"
                class="filter__input"
              >
                <el-option v-for="item in countries" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Criterio: </label>
              <el-select
                placeholder="Selecciona un Criterio"
                ref="search"
                v-model="searchCriterion"
                size="small"
                class="filter__input"
              >
                <el-option v-for="(item, index) in criterions" :key="index" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Consulta: </label>
              <el-input
                placeholder="Buscar"
                ref="search"
                @keyup.enter.native="filterDocuments"
                v-model="searchQuery"
                type="text"
                size="small"
                class="filter__input"
              >
                <span slot="suffix" v-show="searchQuery" @click="clearSearchInput('searchQuery')">
                  <i
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    style="cursor: pointer"
                  ></i>
                </span>
              </el-input>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Texto: </label>
              <el-input
                placeholder="Buscar"
                ref="search"
                @keyup.enter.native="filterDocuments"
                v-model="text"
                type="text"
                size="small"
                class="filter__input"
              >
                <span slot="suffix" v-show="text" @click="clearSearchInput('text')">
                  <i
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    style="cursor: pointer"
                  ></i>
                </span>
              </el-input>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Desechar: </label>
              <el-select
                placeholder="Selecciona True o False"
                ref="search"
                v-model="discard"
                size="small"
                class="filter__input"
              >
                <el-option :key="''" :label="''" :value="''"> </el-option>
                <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
                <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Revisado: </label>
              <el-select
                placeholder="Selecciona True o False"
                ref="search"
                v-model="reviewed"
                size="small"
                class="filter__input"
              >
                <el-option :key="''" :label="''" :value="''"> </el-option>
                <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
                <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Memoria Histórica: </label>
              <el-select
                placeholder="Selecciona True o False"
                ref="search"
                v-model="historicalMemory"
                size="small"
                class="filter__input"
              >
                <el-option :key="''" :label="''" :value="''"> </el-option>
                <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
                <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Educación política: </label>
              <el-select
                placeholder="Selecciona True o False"
                ref="search"
                v-model="politicalEducation"
                size="small"
                class="filter__input"
              >
                <el-option :key="''" :label="''" :value="''"> </el-option>
                <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
                <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
              </el-select>
            </div>
            <div class="filter">
              <label for="filter__select" class="label filter__label"> Imagen del pasado: </label>
              <el-select
                placeholder="Selecciona True o False"
                ref="search"
                v-model="imageOfPast"
                size="small"
                class="filter__input"
              >
                <el-option :key="''" :label="''" :value="''"> </el-option>
                <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
                <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
              </el-select>
            </div>
            <el-button
              type="warning"
              plain
              size="small"
              @click="filterDocuments"
              :loading="filterButtonLoadingAnimation"
            >
              Filtrar
            </el-button>
          </div>
        </el-popover>
      </div>
      <div class="tools-bar__item">
        <el-tooltip
          :content="showDocumentView ? 'Hide preview' : 'Show preview'"
          placement="top"
          effect="dark"
          :open-delay="500"
        >
          <el-button
            type="warning"
            icon="ion ion-information-outline"
            size="small"
            circle
            plain
            @click="toggleDocumentView"
          >
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <div v-show="!resizing" class="documents-list-table-container">
      <DocumentsListTable
        :textSearch="textSearch"
        :flagDate="flagDate"
        :flagFilter="flagFilter"
        :totalLength="showTotal"
        :currentPage="currentPage"
        v-loading="showLoadingAnimation"
        @statusChanged="fetchDocuments"
        @setPage="filterDocuments"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import filterOptions from "../../../../utils/filterOptions";
import seriesOptions from "../../../../utils/seriesOptions";
import DocumentsListTable from "./components/DocumentsListTable/Index.vue";

export default {
  name: "DocumentsList",
  components: {
    DocumentsListTable,
  },
  mixins: [filterOptions, seriesOptions],
  props: {
    resizing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderBy: "newest",
      datePrickerOptions: {
        disabledDate: (time) => time.getTime() > Date.now(),
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dates: null,
      textSearch: "",
      author: null,
      authorLocation: null,
      receiverLocation: null,
      searchCountry: null,
      series: null,
      searchCriterion: null,
      searchQuery: null,
      text: null,
      discard: null,
      reviewed: null,
      historicalMemory: null,
      politicalEducation: null,
      imageOfPast: null,
      textUser: "",
      filterProps: { multiple: false, expandTrigger: "hover" || "click" },
      showLoadingAnimation: false,
      filterButtonLoadingAnimation: false,
      visible: false,
      flagDate: false,
      flagFilter: false,
      // keyRender: 0,
    };
  },
  computed: {
    ...mapGetters("appLayout", ["showDocumentView"]),
    ...mapState("documents", ["countries", "criterions", "seriesArr", "docParams", "docFilterParams"]),
    ...mapGetters("documents", ["showDocuments", "showTotal", "showParams", "showFilterParams"]),
    ...mapState("user", ["user"]),
    currentPage() {
      return (this.showParams.skip / this.showParams.limit) + 1;
    },
  },
  watch: {
    async dates() {
      this.showLoadingAnimation = true;
      if (this.dates === null) {
        await this.getAllDocuments();
      } else {
        const payload = this.docFilterParams;
        payload.dates = {
          startDate: this.dates[0],
          endDate: this.dates[1],
        };
        this.flagDate = true;
        await this.getFilterDocuments(payload);
      }
      this.showLoadingAnimation = false;
    },
  },
  async created() {
    if (!this.$route.params.page) {
      await this.fetchDocuments();
    }
  },
  methods: {
    ...mapActions("appLayout", ["toggleDocumentView"]),
    ...mapActions("documents", ["getAllDocuments", "getFilterDocuments", "getParams", "getFilterParams"]),
    async fetchDocuments() {
      this.showLoadingAnimation = true;
      await this.getAllDocuments();
      this.showLoadingAnimation = false;
    },
    async sendUser() {
      this.showLoadingAnimation = true;
      this.$store.commit("user/setUser", { name: this.textUser });
      this.textUser = "";
      this.showLoadingAnimation = false;
    },
    async filterDocuments() {
      this.filterButtonLoadingAnimation = true;
      this.showLoadingAnimation = true;
      const payload = this.docFilterParams;
      this.flagDate = false;
      this.flagFilter = true;
      Object.keys(payload).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        if (this[key]) {
          payload[key] = this[key];
        }
      });
      await this.getFilterParams(payload);
      await this.getFilterDocuments();
      this.showLoadingAnimation = false;
      this.filterButtonLoadingAnimation = false;
    },
    async clearSearchInput(string) {
      this.showLoadingAnimation = true;
      this[string] = null;
      this.showLoadingAnimation = false;
    },
    async clearUserInput() {
      this.showLoadingAnimation = true;
      this.$refs.user.clear();
      this.showLoadingAnimation = false;
    },
    async closeContainerFilter() {
      this.showLoadingAnimation = true;
      this.visible = false;
      this.flagFilter = false;
      this.clearFilterInputs();
      if (this.$route === "/") {
        await this.getAllDocuments();
      } else {
        await this.getFilterDocuments();
      }
      this.showLoadingAnimation = false;
    },
    async clearFilterInputs() {
      this.author = null;
      this.authorLocation = null;
      this.receiverLocation = null;
      this.searchCountry = null;
      this.searchCriterion = null;
      this.searchQuery = null;
      this.series = null;
      this.text = null;
      this.discard = null;
      this.reviewed = null;
      this.historicalMemory = null;
      this.politicalEducation = null;
      this.imageOfPast = null;
      const payload = this.docFilterParams;
      Object.keys(payload).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        if (key !== 'source') {
          payload[key] = this[key];
        }
      });
      await this.getFilterParams(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.documents-list {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  max-height: 100vh;
  align-items: center;
}

.tools-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: sticky;
  right: 0;
  gap: 1rem;
  .user-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 290px;
    .user-info {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      p,
      h3 {
        margin-inline-end: 5px;
        margin-block: 0 10px;
      }
    }
    .user-assign {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      .filter {
        margin-block-end: 0;
        &__label {
          width: auto;
        }
        &__input {
          width: 170px;
        }
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    max-width: 10.5rem;
    align-self: flex-end;
  }
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-block-end: 5px;
  &__label {
    width: 120px;
  }
  &__input {
    width: 200px;
  }
}

.search-button {
  height: 30px;
  color: #e6a23c;
  background: #fdf6ec;
  border-color: #f5dab1;
  border: none;
  cursor: pointer;
  &:hover {
    background: #e6a23c;
    border-color: #e6a23c;
    color: #fff;
  }
}
.close-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
  height: 30px;
}
.label {
  color: gray;
  padding-left: 0.2rem;
}

.documents-list-table-container {
  height: 100%;
}

::v-deep .el-input-group__append {
  padding: 0;
}
</style>
