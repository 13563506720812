<template >
  <div class="avigail-home" v-show="!resizingWholeView">
    <NavigationBar />
    <main class="avigail-home__main-content" @mouseup="resizingPanels = false">
      <DocumentsList
        class="documents-list"
        :style="{ width: showDocumentView ? '' : '100%' }"
        :resizing="resizingPanels"
      />
      <template v-if="showDocumentView">
        <Splitter @mousedown.native="resizingPanels = true" />
        <DocumentView class="document-view" :resizing="resizingPanels" />
      </template>
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import NavigationBar from '../../components/NavigationBar/Index.vue';
import Splitter from '../../components/Splitter/Index.vue';
import DocumentsList from './components/DocumentsList/Index.vue';
import DocumentView from './components/DocumentView/Index.vue';

export default {
  name: 'Home',
  components: {
    NavigationBar,
    DocumentsList,
    DocumentView,
    Splitter,
  },
  data() {
    return {
      resizingPanels: false,
      resizingWholeView: false,
    };
  },
  watch: {
    resizingWholeView() {
      if (this.resizingWholeView === true) {
        const loadingInstance = Loading.service({ text: 'Resizing...' });
        setTimeout(() => {
          loadingInstance.close();
          this.resizingWholeView = false;
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters('appLayout', ['showDocumentView']),
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.resizingWholeView = true;
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.resizingWholeView = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.avigail-home {
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100vh;
  &__main-content {
    display: flex;
  }
  overflow: hidden;
}
.documents-list {
  overflow: auto;
  box-sizing: border-box;
  width: 60%;
  min-width: 470px;
}
.document-view {
  overflow: auto;
  box-sizing: border-box;
  width: 40%;
  min-width: 20%;
}
</style>
