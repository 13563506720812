<template>
  <div class="navigation-bar">
    <img
      src="../../assets/images/Logo-Gumelab (2).png"
      alt="app-logo"
      class="navigation-bar__logo"
    />
    <nav class="navigation">
      <ul class="navigation__ul">
        <li>
          <a
            class="navigation__button"
            :class="$route.path === '/' ? 'link-active' : ''"
            @click="goToPath('/')"
          >
            <i class="ion ion-home-outline" />
            <span class="navigation__button-label">Home</span>
          </a>
        </li>
        <li v-for="(button, index) in documentStatusButtons" :key="index">
          <a
            class="navigation__button"
            :class="$route.path === `/${button.route}` ? 'link-active' : ''"
            @click="goToPath(button.route)"
          >
            <i :class="button.iconLabel" />
            <span class="navigation__button-label" v-html="button.label"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import documentStatusButtons from '../../utils/documentStatusButtons';

export default {
  name: 'NavigationBar',
  data() {
    return {
      documentStatusButtons,
    };
  },
  methods: {
    ...mapActions("documents", [
      "getAllDocuments",
      "getFilterDocuments",
      "getParams",
      "getFilterParams",
    ]),
    async goToPath(routePath) {
      await this.$router.push({ path: routePath }).catch((err) => {
        if (
          err.name !== 'NavigationDuplicated'
          && !err.message.includes(
            'Avoided redundant navigation to current location',
          )
        ) {
          // logError(err);
        }
      });
      const params = {
        skip: 0,
        limit: 20,
      };
      const filterParams = {
        source: routePath,
      };
      await this.getParams(params);
      await this.getFilterParams(filterParams);
      if (routePath === "/") {
        const sourceParams = {
          source: null,
        };
        await this.getFilterParams(sourceParams);
        await this.getFilterDocuments();
      } else {
        await this.getFilterDocuments();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-bar {
  height: 100vh;
  width: 5rem;
  background-color: #43255c;;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  &__logo {
    width: 80%;
    padding: 1.4rem 0.2rem;
  }
  &__divider {
    margin: auto;
    width: 70%;
  }
}
.navigation {
  width: 100%;
  &__ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
    min-height: 3rem;
    text-align: center;
    color: rgb(206, 206, 206);
    text-decoration: none;
    font-weight: 300;
    font-size: 1.8rem;
    cursor: pointer;
    @extend %anim-transition;
    &:hover {
      color: white;
      background-color: lighten(#fab613, 10%);
    }
    &--exit {
      width: 100%;
      border: none;
      background-color: transparent;
    }
    i {
      width: 35px;
      height: 35px;
    }
  }
  &__button-label {
    font-size: 0.9rem;
  }
}
.link-active {
  color: white;
  background-color: lighten(#fab613, 10%);
}
</style>
