export default {
  data() {
    return {
      filterOptions: [
        {
          value: "criterion",
          label: "Criterion",
          children: [
            {
              value: "searchCountry",
              label: "Search by country",
            },
            {
              value: "searchCriterion",
              label: "Search by criterion",
            },
            {
              value: "searchQuery",
              label: "Search by query",
            },
          ],
        },
      ],
    };
  },
};
