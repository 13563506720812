export default {
  data() {
    return {
      seriesOptions: [
        {
          value: "Dignity",
          label: "Dignity",
        },
        {
          value: "Los 80",
          label: "Los 80",
        },
        {
          value: "Escobar, el patrón del mal",
          label: "Escobar, el patrón del mal",
        },
        {
          value: "Narcos",
          label: "Narcos",
        },
        {
          value: "Tres Caínes",
          label: "Tres Caínes",
        },
      ],
    };
  },
};
