<template>
  <div class="splitter-gutter"
  >
    <div class="splitter-gutter__block"></div>
  </div>
</template>

<script>
export default {
  name: 'Splitter',
  mounted() {
    this.handleSplitter();
  },
  methods: {
    handleSplitter() {
      const gutter = document.querySelector('.splitter-gutter');
      const leftPanel = gutter.previousSibling;
      const rightPanel = gutter.nextSibling;

      function splitterResizer(resizerEvent) {
        const leftPanelWidth = leftPanel.getBoundingClientRect().width;
        const rightPanelWidth = rightPanel.getBoundingClientRect().width;
        const leftPanelWidthPercentage = (leftPanelWidth / (leftPanelWidth + rightPanelWidth)) * 100;
        const rightPanelWidthPercentage = 100 - leftPanelWidthPercentage;

        function mousemove(mousemoveEvent) {
          const mouseMovement = resizerEvent.x - mousemoveEvent.x;
          leftPanel.style.width = `calc(${leftPanelWidthPercentage}% - ${mouseMovement}px)`;
          rightPanel.style.width = `calc(${rightPanelWidthPercentage}% + ${mouseMovement}px)`;
        }
        function mouseup() {
          window.removeEventListener('mousemove', mousemove);
          window.removeEventListener('mouseup', mouseup);
        }
        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);
      }
      gutter.addEventListener('mousedown', splitterResizer);
    },
  },
};
</script>

<style lang="scss">
.splitter-gutter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5px;
  height: 100%;
  background: #f6f6f6;
  cursor: col-resize;
  z-index: 1;
  &__block {
    width: 70%;
    height: 11px;
    background-color: #707070;
    border-radius: 8px;
  }
}
</style>
