<template>
  <div
    class="document-view"
    v-loading="resizing ? true : false"
    :element-loading-text="resizing ? 'Redimensionando...' : ''"
  >
    <div v-if="currentLink" class="pdf-container">
      <iframe
        v-show="resizing === false"
        class="pdf-container__page"
        :src="currentLink"
      >
      </iframe>
    </div>
    <div v-else class="empty-fill">
      <img
        src="@/assets/images/empty-document-image-fill.svg"
        alt="No document selected"
      />
      <label class="empty-fill__text"
        >Click on a document to view it</label
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DocumentView',
  props: {
    resizing: {
      type: Boolean,
      required: true,
    },
  },
  components: {
  },
  data() {
    return {
      currentLink: null,
      file: null,
      src: '',
      numPages: 0,
      scrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters('files', {
      showCurrentLink: 'showCurrentLink',
    }),
  },
  watch: {
    showCurrentLink() {
      this.currentLink = this.showCurrentLink;
    },
    resizing() {
      if (this.resizing) {
        this.scrollPosition = this.$el.scrollTop;
      }
      if (!this.resizing) {
        setTimeout(() => {
          this.$el.scrollTop = this.scrollPosition;
        }, 0);
      }
    },
  },
  mounted() {
    this.$emit('mounted');
  },
};
</script>

<style lang="scss" scoped>
.document-view {
  display: flex;
  flex-direction: column;
}
.toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}
.pdf-container {
  align-self: center;
  width: 99%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__page {
    width: 100%;
    height: 99vh;
  }
}
.empty-fill {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
  align-self: center;
  margin: auto;
  &__text {
    color: #828282;
    text-align: center;
  }
}
</style>
